import * as React from "react";
import { useTranslation } from "react-i18next";

export function CommonFunctions() {
  const { t } = useTranslation();

  return (
    <section className="ins-page-section">
      <div className="container">
        <h2 className="section-title mb-5 text-center">
          {t("home.section.getToKnowFunctions.headerPart1")}{" "}
          <span className="text-primary">
            {t("home.section.getToKnowFunctions.headerPart2")}
          </span>
        </h2>

        <div className="row justify-content-md-center mb-5">
          <div className="col-md-4">
            <h4>{t("home.section.getToKnowFunctions.feature1.header")}</h4>
            <p>{t("home.section.getToKnowFunctions.feature1.description")}</p>
          </div>

          <div className="col-md-4 mb-5 mb-md-0">
            <img
              src="/images/home/get-to-know/1-kontroluj-stan-pojazdow.png"
              alt="Kontroluj stan pojazdów"
              className="img-fluid"
            />
          </div>
        </div>
        <div className="row justify-content-md-center mb-5">
          <div className="col-md-4 order-md-2">
            <h4>{t("home.section.getToKnowFunctions.feature2.header")}</h4>
            <p>{t("home.section.getToKnowFunctions.feature2.description")}</p>
          </div>
          <div className="col-md-4 mb-5 mb-md-0">
            <img
              src="/images/home/get-to-know/2-zarzadzaj-uszkodzeniami.png"
              alt="Zarządzasz uszkodzeniami"
              className="img-fluid"
            />
          </div>
        </div>

        <div className="row justify-content-md-center mb-5">
          <div className="col-md-4">
            <h4>{t("home.section.getToKnowFunctions.feature3.header")}</h4>
            <p>{t("home.section.getToKnowFunctions.feature3.description")}</p>
          </div>

          <div className="col-md-4 mb-5 mb-md-0">
            <img
              src="/images/home/get-to-know/3-rozliczaj-szkody.png"
              alt="Rozliczaj szkody"
              className="img-fluid"
            />
          </div>
        </div>
        <div className="row justify-content-md-center mb-5">
          <div className="col-md-4 order-md-2">
            <h4>{t("home.section.getToKnowFunctions.feature4.header")}</h4>
            <p>{t("home.section.getToKnowFunctions.feature4.description")}</p>
          </div>
          <div className="col-md-4 mb-5 mb-md-0">
            <img
              src="/images/home/get-to-know/4-buduj-szablony.png"
              alt="Buduj własne szablony"
              className="img-fluid"
            />
          </div>
        </div>

        <div className="row justify-content-md-center">
          <div className="col-md-4">
            <h4>{t("home.section.getToKnowFunctions.feature5.header")}</h4>
            <p>{t("home.section.getToKnowFunctions.feature5.description")}</p>
          </div>

          <div className="col-md-4 mb-md-0">
            <img
              src="/images/home/get-to-know/5-panel-managera.png"
              alt="Panel managera"
              className="img-fluid"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
